import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from "./components/home/home.component";
import { ProviderComponent } from "./provider/provider.component";
import { HomeProviderComponent } from "./provider/home-provider/home-provider.component";
import { TableWallpaperComponent } from "./provider/table-wallpaper/table-wallpaper.component";
import { CustomerComponent } from "./customer/customer.component";
import { HomeCustomerComponent } from "./customer/home-customer/home-customer.component";
import { ViewWallpaperComponent } from "./customer/view-wallpaper/view-wallpaper.component";
import { SearchComponent } from "./customer/search/search.component";

import { AuthGuard } from "./services/guards/auth.guard";
import { ProfileProviderComponent } from "./provider/profile-provider/profile-provider.component";
import { ViewimgProfileComponent } from "./provider/viewimg-profile/viewimg-profile.component";
import { RegisterComponent } from "./register/register.component";
import { NewsComponent } from "./customer/news/news.component";
import { AddWallpaperComponent } from "./customer/add-wallpaper/add-wallpaper.component";
import { ViewProfileComponent } from "./customer/view-profile/view-profile.component";
import { EditProfileComponent } from "./customer/edit-profile/edit-profile.component";
import { ViewImgProfileComponent } from "./customer/view-img-profile/view-img-profile.component";
import { EditWallpaperComponent } from "./customer/edit-wallpaper/edit-wallpaper.component";
import { TrendsComponent } from "./customer/trends/trends.component";
import { PublicProfileComponent } from "./customer/public-profile/public-profile.component";
import { PublicWallpaperComponent } from './customer/public-wallpaper/public-wallpaper.component';
import { ViewImgProfilePublicComponent } from './customer/view-img-profile-public/view-img-profile-public.component';
import { AddVideoComponent } from './customer/add-video/add-video.component';
import { ViewVideosComponent } from './customer/view-videos/view-videos.component';
import { ViewVideoDetailComponent } from './customer/view-video-detail/view-video-detail.component';
import { AddVideoExternoComponent } from './customer/add-video-externo/add-video-externo.component';
import { LoginComponent } from './login/login.component';
const appRoutes: Routes = [
  { path: "registrarse", component: RegisterComponent },
  { path: "login", component: LoginComponent },

  {
    path: "",
    component: CustomerComponent,
    children: [
      { path: "news",canActivate: [AuthGuard], component: NewsComponent },
      { path: "trends", canActivate: [AuthGuard],component: TrendsComponent },
      { path: "public-wallpaper", canActivate: [AuthGuard],component: PublicWallpaperComponent },
      { path: "public-profile", canActivate: [AuthGuard],component: PublicProfileComponent },
      {
        path: "edit-wallpaper",
        canActivate: [AuthGuard],
        component: EditWallpaperComponent,
      },
      {
        path: "view-img-profile",
        canActivate: [AuthGuard],
        component: ViewImgProfileComponent,
      },
      {
        path: "view-img-profile-public",
        canActivate: [AuthGuard],
        component: ViewImgProfilePublicComponent,
      },
      {
        path: "edit-profile",
        canActivate: [AuthGuard],
        component: EditProfileComponent,
      },
      {
        path: "profile",
        canActivate: [AuthGuard],
        component: ViewProfileComponent,
      },
      {
        path: "add-wallpaper",
        canActivate: [AuthGuard],
        component: AddWallpaperComponent,
      },
      {
        path: "add-video",
        canActivate: [AuthGuard],
        component: AddVideoComponent,
      },
      {
        path: "add-video-externo",
        canActivate: [AuthGuard],
        component: AddVideoExternoComponent,
      },
      {
        path: "view-videos",
        canActivate: [AuthGuard],
        component: ViewVideosComponent,
      },
      {
        path: "detail-video",
        canActivate: [AuthGuard],
        component: ViewVideoDetailComponent,
      },
      { path: "ver-fondo", component: ViewWallpaperComponent },
      { path: "", redirectTo: "/login", pathMatch: "full" },
    ],
  },
  {
    path: "",
    component: ProviderComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "inicio-provider", component: HomeProviderComponent },
      { path: "view-wallpapers", component: TableWallpaperComponent },
      { path: "profile-provider", component: ProfileProviderComponent },
      { path: "profile-img", component: ViewimgProfileComponent },
      { path: "", redirectTo: "/login", pathMatch: "full" },
    ],
  },
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "**", redirectTo: "/login", pathMatch: "full" },
];

export const APP_ROUTES = RouterModule.forRoot(appRoutes, { useHash: false });
