import { Component, OnInit } from "@angular/core";
import { FondosService } from "src/app/services/fondos.service";
import { WallPaper } from "src/app/clases/wallpaper.class";
import { DataService } from "src/app/services/data.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-home-customer",
  templateUrl: "./home-customer.component.html",
  styleUrls: ["./home-customer.component.scss"],
})
export class HomeCustomerComponent implements OnInit {
  fondos: WallPaper[] = [];
  btnAnterior = true;
  btnNext = false;
  limite: number = 6;
  constructor(
    public wallpaperService: FondosService,
    public dataService: DataService,
    private router: Router
  ) {
    this.wallpaperService
      .getWallPapersWithLimit("anime", this.limite)
      .subscribe((data) => {
        this.fondos = data;
        this.wallpaperService.lastDocument = this.fondos[
          this.fondos.length - 1
        ];
      });
  }
  nextElemets() {
    this.wallpaperService
      .getWallPapersWithLimitNetx(
        "anime",
        this.limite,
        this.fondos[this.fondos.length - 1]
      )
      .subscribe((data) => {
        if (data.length == 0) {
          this.btnNext = true;
          return;
        }
        this.fondos = data;
        this.btnAnterior = false;
      });
  }
  beforeElements() {
    this.wallpaperService
      .getWallPapersWithLimitBefore("anime", this.limite, this.fondos[0])
      .subscribe((data) => {
        if (data.length == 0) {
          this.btnAnterior = true;
          this.btnNext = false;
          return;
        }
        this.btnNext = false;
        this.fondos = data;
      });
  }

  ngOnInit() {}
  showImage(item: WallPaper) {
    this.dataService.wallpaperActual = item;
    this.router.navigate(["ver-fondo"]);
  }
}
