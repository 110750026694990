import { Component, OnInit } from "@angular/core";
import { FondosService } from "src/app/services/fondos.service";
import { WallPaper } from "src/app/clases/wallpaper.class";
import { DataService } from "src/app/services/data.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  fondos: WallPaper[] = [];
  limite: number = 6;
  constructor(
    public wallpaperService: FondosService,
    public dataService: DataService,
    private router: Router
  ) {}
  public cargarDocuments() {
    this.wallpaperService.getWallPapersWithSearch(
      this.dataService.categoriaBusqueda,
      this.limite
    );
  }
  nextElemets() {
    this.wallpaperService.getWallPapersWithSearchNetx(
      this.dataService.categoriaBusqueda,
      this.limite
    );
  }
  beforeElements() {
    this.wallpaperService.getWallPapersWithSearchBefore(
      this.dataService.categoriaBusqueda,
      this.limite
    );
  }
  showImage(item: WallPaper) {
    this.dataService.wallpaperActual = item;
    this.router.navigate(["ver-fondo"]);
  }

  ngOnInit() {}
}
