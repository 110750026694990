import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "../services/data.service";
import { SearchComponent } from "./search/search.component";
import { AuthService } from "../services/auth.service";
import { Usuario } from "../clases/usuario.class";
import { MatDialog } from "@angular/material/dialog";
import { LoginDialogComponent } from "./login-dialog/login-dialog.component";
import { UsuarioService } from "../services/usuario.service";
import { ViewProfileService } from "../services/view-profile.service";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
})
export class CustomerComponent implements OnInit {
  opened = false;
  title = "wallpaperProyect";
  limite: number = 6;
  usuarioActual: Usuario = new Usuario();
  source: string = "../../../assets/img/perfilfoto.jpg";
  isLogin: boolean = false;

  @ViewChild(SearchComponent, { static: false }) hijo: SearchComponent;
  constructor(
    public dataService: DataService,
    private router: Router,
    public wallpaperService: ViewProfileService,
    private authService: AuthService,
    public dialog: MatDialog,
    private usuarioService: UsuarioService
  ) {
    this.authService.isLogin().subscribe((user) => {
      if (user) {
        this.usuarioService.getUserbyId(user.uid).subscribe((res) => {
          this.usuarioActual = res[0];

          if (this.usuarioActual.rutaprofileImg != "") {
            this.source = this.usuarioActual.rutaprofileImg;
          }
        });
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    });
  }

  ngOnInit() {}

  cerrarsesion() {
    this.authService.logoutUser();
    this.wallpaperService.listWallpapersProfile = [];
    this.opened = false;
  }
  viewLogin() {
    this.dataService.dialogRef = this.dialog.open(LoginDialogComponent);
    this.opened= false;
  }
  goToAddWallpaper() {
    if (this.authService.estaLogeado() == true) {
      this.router.navigate(["/add-wallpaper"]);
    } else {
      this.dataService.dialogRef = this.dialog.open(LoginDialogComponent);
    }
  }
  goToViewProfile() {
    if (this.authService.estaLogeado() == true) {
      this.router.navigate(["/profile"]);
    } else {
      this.dataService.dialogRef = this.dialog.open(LoginDialogComponent);
    }
  }
  goToViewVideos() {
    if (this.authService.estaLogeado() == true) {
      this.router.navigate(["/view-videos"]);
    } else {
      this.dataService.dialogRef = this.dialog.open(LoginDialogComponent);
    }
  }
}
