import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

//importaciones de observables y maps
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
//importar angular storage
import { AngularFireStorage } from "@angular/fire/storage";
import { WallPaper } from "src/app/clases/wallpaper.class";
import { FondosService } from "src/app/services/fondos.service";
import { AuthService } from "src/app/services/auth.service";
import { Hora } from "src/app/clases/Hora";
import { VideoWall } from 'src/app/clases/video.class';
import { VideoService } from 'src/app/services/video.service';

@Component({
  selector: 'app-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss']
})
export class AddVideoComponent implements OnInit {
  txtFile: boolean = false;
  fechas: Hora = new Hora();
  form: FormGroup;
  videoActual: VideoWall = new VideoWall();
  fileImg: File;
  fileMiniatura: File;
  source: any = "../../../assets/svg/video.svg";
  sourceMiniatura: any = "../../../assets/svg/image1.svg";
  format: string = 'image';
  uploadPorcent: Observable<number>;
  uploadPorcentMiniatura: Observable<number>;
  urlImage: Observable<string>;
  message: boolean = false;
  showProgressBar: boolean = false;
  btnOtherImage: boolean = true;
  sizeCollecion: number;

  @Output() onChange: EventEmitter<File> = new EventEmitter<File>();

  constructor(
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private videoService: VideoService,
    private authService: AuthService
  ) { 
    this.videoService.getNumeroDocuemntos().subscribe((res) => {
      this.sizeCollecion = res.size;
    });
    
    
  }

  ngOnInit() {
    this.crearForm();
  }
  crearForm() {
    this.form = this.fb.group({
      nameImage: ["", Validators.compose([Validators.required])],
      categoria: ["", Validators.compose([Validators.required])],
      estadoPublico: ["", Validators.compose([Validators.required])],
      imagen: ["", Validators.compose([Validators.required])],
      miniature: ["", Validators.compose([Validators.required])],
    });
  }

  onSelectFile(event) {
    this.fileMiniatura =  event.target.files[0];
    if (this.fileMiniatura) {
      var reader1 = new FileReader();
      reader1.readAsDataURL(this.fileMiniatura);
      if(this.fileMiniatura.type.indexOf('image')> -1){
        this.format = 'image';
      } else if(this.fileMiniatura.type.indexOf('video')> -1){
        this.format = 'video';
      }
      reader1.onload = (event) => {
        this.sourceMiniatura = (<FileReader>event.target).result;
      }
    }
  }


  CargarArchivo(e) {
    this.fileImg = e.target.files[0];
    if( this.fileImg){
      this.source = "../../../assets/svg/check.svg";
    }
    // console.log(this.fileImg);
    // if(this.fileImg.type.indexOf('image')> -1){
    //   this.format = 'image';
    // } else if(this.fileImg.type.indexOf('video')> -1){
    //   this.format = 'video';
    // }
    // this.projectImage(this.fileImg);
   
    
  }



  projectImage(file: File) {
    let reader = new FileReader();
    // TODO: Define type of 'e'
    reader.onload = (e: any) => {
      // Simply set e.target.result as our <img> src in the layout
      this.source = e.target.result;

      this.onChange.emit(file);
    };
    // This will process our file and get it's attributes/data
    reader.readAsDataURL(file);
  }
  agregarImagenes() {
    var nombreImagen = this.form.get("nameImage").value;
    var categoria = this.form.get("categoria").value;
    console.log(nombreImagen + "  " + categoria);
  }
  subirImagenes() {
    this.desavilitarCampos();
    this.subirMiniatura();
    this.showProgressBar = true;
    const id = this.fechas.getFechaHoy() + this.authService.getIdUsuario();
    const filepath = "videos/" + id;
    this.videoActual.nameInterno = filepath;
    const ref = this.storage.ref(filepath);
    const task = this.storage.upload(filepath, this.fileImg);
    this.uploadPorcent = task.percentageChanges();
    task
      .snapshotChanges()
      .pipe(
        finalize(async () => {
          this.urlImage = ref.getDownloadURL();
          this.urlImage.subscribe((res: string) => {
            this.videoActual.ruta = res;
            this.videoActual.nombre = this.form.get("nameImage").value;
            this.videoActual.categoria = this.form.get("categoria").value;
            this.videoActual.statePublic = this.form.get("estadoPublico").value;
            this.videoActual.userId = this.authService.getIdUsuario();
            this.videoActual.fechaCreacion = new Date();
            this.videoActual.likes = 0;
            this.videoActual.reported = false;
            this.videoActual.id = this.sizeCollecion += 1;
            this.videoService.addVideos(this.videoActual);
            this.showProgressBar = false;
            this.btnOtherImage = false;
          });
        })
      )
      .subscribe();
  }
  subirMiniatura(){
    const id = this.fechas.getFechaHoy() + this.authService.getIdUsuario();
    const filepath = "miniaturas/" + id;
    this.videoActual.nameinternoMiniatura = filepath;
    const ref = this.storage.ref(filepath);
    const task = this.storage.upload(filepath, this.fileMiniatura);
    this.uploadPorcentMiniatura = task.percentageChanges();
    task
      .snapshotChanges()
      .pipe(
        finalize(async () => {
          this.urlImage = ref.getDownloadURL();
          this.urlImage.subscribe((res: string) => {
            this.videoActual.urlMiniatura = res;
          });
        })
      )
      .subscribe();

  }
  desavilitarCampos() {
    this.form.controls["nameImage"].disable();
    this.form.controls["categoria"].disable();
    this.form.controls["estadoPublico"].disable();
    this.form.controls["imagen"].disable();
    this.form.controls["miniature"].disable();
    this.txtFile = true;
  }
  nuevaImagen() {
    this.fileImg = null;
    this.fileMiniatura = null;
    this.uploadPorcent = new Observable();
    this.uploadPorcentMiniatura = new Observable();
    this.form.controls["nameImage"].setValue("");
    this.form.controls["categoria"].setValue("");
    this.form.controls["estadoPublico"].setValue("");
    this.source = "../../../assets/svg/img-1.svg";
    this.sourceMiniatura = "../../../assets/svg/image1.svg";
    this.form.controls["nameImage"].enable();
    this.form.controls["categoria"].enable();
    this.form.controls["estadoPublico"].enable();
    this.form.controls["imagen"].enable();
    this.form.controls["miniature"].enable();
    this.btnOtherImage = true;
    this.txtFile = false;
  }

}
