import { Component, OnInit } from "@angular/core";
import { Operadores } from "src/app/clases/operadores.class";
import { TrendsService } from "src/app/services/trends.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { Router } from "@angular/router";
import { Usuario } from "src/app/clases/usuario.class";
import { DataService } from "src/app/services/data.service";
import { WallPaper } from 'src/app/clases/wallpaper.class';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: "app-trends",
  templateUrl: "./trends.component.html",
  styleUrls: ["./trends.component.scss"],
})
export class TrendsComponent implements OnInit {
  limite: number = 10;
  operadorMultiplos: Operadores;
  constructor(
    public wallpaperService: TrendsService,
    public usuarioService: UsuarioService,
    private router: Router,
    public dataService: DataService
  ) {
    this.operadorMultiplos = new Operadores();
    if (this.wallpaperService.listWallpapersProfile.length == 0) {
      this.wallpaperService.getWallPapersWithTrends(this.limite);
    }
    if (this.usuarioService.listUsuarios.length == 0) {
      this.usuarioService.getusersWithQualification(10);
    }
  }
  showProfile(UsuarioTemp: Usuario) {
    this.dataService.usuarioActual = UsuarioTemp;
    this.router.navigate(["public-profile"]);
  }

  ngOnInit() { }
  ShowMoreDocuments() {
    this.wallpaperService.getWallPapersWithTrendsNext(this.limite);
  }
  showWallpaper(fondo: WallPaper) {
    this.dataService.wallpaperActual = fondo;
    this.router.navigate(['/public-wallpaper'])
  }
}
