import { Component, OnInit } from '@angular/core';
import { VideoWall } from 'src/app/clases/video.class';
import { DataService } from 'src/app/services/data.service';
import { VideoService } from 'src/app/services/video.service';

@Component({
  selector: 'app-view-video-detail',
  templateUrl: './view-video-detail.component.html',
  styleUrls: ['./view-video-detail.component.scss']
})
export class ViewVideoDetailComponent implements OnInit {
  videoA: VideoWall = new VideoWall();

  constructor(public dataService: DataService) { 
    this.videoA = dataService.videoActual;
  }

  ngOnInit() {
  }

}
