import { Injectable } from "@angular/core";
//importaciones de angular firebase
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";

//importaciones de observables y maps
import { Observable } from "rxjs";
import { map, flatMap, subscribeOn } from "rxjs/operators";
import { VideoWall } from '../clases/video.class';

// importacion de clases
import { WallPaper } from "../clases/wallpaper.class";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  private nameCollection = "/videos";
  ColeccionFondos: AngularFirestoreCollection<VideoWall> = null;
  listDocuments: Observable<any[]>;

  listVideosProfile: VideoWall[] = [];
  lastDocumentProfile: VideoWall;

  msgResult2: boolean = false;

  btnNextProfiles = false;

  constructor(private db: AngularFirestore, private authService: AuthService) { 
    this.ColeccionFondos = db.collection(this.nameCollection);
  }
  //Crea un Video
  addVideos(videof: VideoWall) {
    this.ColeccionFondos.add({ ...videof });
  }
  //optiene objetos con el id del usuario logeado.
  getVideosWithDateAndUser(limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref
        .where("userId", "==", this.authService.getIdUsuario())
        .limit(limite)
        .orderBy("fechaCreacion", "desc")
    );
    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;

          return { documentID, ...data };
        })
      )
    );
    this.listDocuments.subscribe((data) => {
      if (this.listVideosProfile.length != 0) {
        this.listVideosProfile = [];
      }
      if (data.length == 0) {
        this.msgResult2 = true;
        this.btnNextProfiles = true;
        return;
      }
      data.forEach((element) => {
        this.listVideosProfile.push(element);
      });
      this.lastDocumentProfile = data[data.length - 1];
      this.btnNextProfiles = false;
      this.msgResult2 = false;
    });
  }
  //optinene el numero de documentos
  getNumeroDocuemntos() {
    return this.db.collection(this.nameCollection).get();
  }
}


