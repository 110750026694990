import { Component, OnInit } from "@angular/core";
import { WallPaper } from "src/app/clases/wallpaper.class";
import { AuthService } from "src/app/services/auth.service";
import { Usuario } from "src/app/clases/usuario.class";
import { DataService } from "src/app/services/data.service";
import { Router } from "@angular/router";
import { ViewProfileService } from "src/app/services/view-profile.service";

@Component({
  selector: "app-view-profile",
  templateUrl: "./view-profile.component.html",
  styleUrls: ["./view-profile.component.scss"],
})
export class ViewProfileComponent implements OnInit {
  grid: string = 'grid';
  source: string = "../../../assets/img/perfilfoto.jpg";
  limite: number = 9;
  usuarioActual: Usuario = new Usuario();
  constructor(
    public wallpaperService: ViewProfileService,
    private authService: AuthService,
    public dataService: DataService,
    private router: Router
  ) {
    this.usuarioActual = this.authService.getusuarioActual();
    if (this.usuarioActual.rutaprofileImg != "") {
      this.source = this.usuarioActual.rutaprofileImg;
    }
    if (this.wallpaperService.listWallpapersProfile.length == 0) {
      this.wallpaperService.getWallPapersWithDateAndUser(this.limite);
    }
  }

  ngOnInit() {}

  ShowMoreDocuments() {
    this.wallpaperService.getWallPapersWithDateNextAndUser(this.limite);
  }
  editarWallpaper(item: WallPaper) {
    this.dataService.wallpaperActual = item;
    this.router.navigate(["edit-wallpaper"]);
  }
  editarGrid(item: string) {
    this.grid = item;
  }
}
