export class Hora {
  
  getFechaHoy() {
    var hoy = new Date();
    var dd = hoy.getDate();
    var mm = hoy.getMonth() + 1;
    var yyyy = hoy.getFullYear();
    var hours = hoy.getHours();
    var minute = hoy.getMinutes();
    var second = hoy.getSeconds();

    dd = this.addZero(dd);
    mm = this.addZero(mm);

    return dd + mm + yyyy + hours + minute + second;
  }
  getFechaFormato() {
    var hoy = new Date();
    var dd = hoy.getDate();
    var mm = hoy.getMonth() + 1;
    var yyyy = hoy.getFullYear();

    dd = this.addZero(dd);
    mm = this.addZero(mm);

    return dd + "/" + mm + "/" + yyyy;
  }
  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
}
