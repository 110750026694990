import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Usuario } from "../clases/usuario.class";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private afsAuth: AngularFireAuth, private router: Router) {}
  //login
  loginConCorreAndEmail(emial: string, pass: string) {
    return new Promise((resolve, reject) => {
      this.afsAuth.auth.signInWithEmailAndPassword(emial, pass).then(
        (userData) => resolve(userData),
        (err) => reject(err)
      );
    });
  }
  //crear nuevo usuario
  createUser(email: string, pass: string) {
    return new Promise((resolve, reject) => {
      this.afsAuth.auth.createUserWithEmailAndPassword(email, pass).then(
        (userData) => resolve(userData),
        (err) => reject(err)
      );
    });
  }
  // mirar si esta logeado
  isLogin() {
    return this.afsAuth.user;
  }
  isLogin2() {
    return new Promise((resolve, reject) => {
      this.afsAuth.user.subscribe(
        (res) => resolve(res),
        () => reject()
      );
    });
  }
  estaLogeado() {
    var usuario = "";
    if (localStorage.getItem("id")) {
      usuario = localStorage.getItem("id");
    }
    if (usuario.length > 3) {
      return true;
    } else {
      return false;
    }
  }
  getIdUsuario() {
    var usuario = "";
    if (localStorage.getItem("id")) {
      usuario = localStorage.getItem("id");
    } else {
      this.logoutUser();
      this.router.navigate(["login-provider"]);
      return;
    }
    return usuario;
  }
  getusuarioActual() {
    var stingvar = "";
    if (localStorage.getItem("user")) {
      stingvar = localStorage.getItem("user");
      return JSON.parse(stingvar);
    } else {
      this.logoutUser();
      this.router.navigate(["login-provider"]);
      return;
    }
  }

  //cerrar Sesion
  logoutUser() {
    return this.afsAuth.auth.signOut().then(() => {
      this.router.navigate(["/login"]);
      localStorage.removeItem("id");
      localStorage.removeItem("user");
    });
  }
}
