import { Component, OnInit } from "@angular/core";
import { FondosService } from "src/app/services/fondos.service";
import { WallPaper } from "src/app/clases/wallpaper.class";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-view-gallery",
  templateUrl: "./view-gallery.component.html",
  styleUrls: ["./view-gallery.component.scss"],
})
export class ViewGalleryComponent implements OnInit {
  fondos: WallPaper[] = [];
  constructor(
    public wallpaperService: FondosService,
    public dataService: DataService
  ) {
    this.wallpaperService
      .getWallPapersWithLimit("anime", 4)
      .subscribe((data) => {
        this.fondos = data;
      });
  }

  ngOnInit() {}
  showImage(item: WallPaper) {
    this.dataService.wallpaperActual = item;
  }
}
