import { Injectable } from "@angular/core";
import { WallPaper } from "../clases/wallpaper.class";
import { MatDialogRef } from "@angular/material/dialog";
import { Usuario } from "../clases/usuario.class";
import { VideoWall } from '../clases/video.class';

@Injectable({
  providedIn: "root",
})
export class DataService {
  categoriaBusqueda: string = "anime";
  wallpaperActual: WallPaper;
  dialogRef: MatDialogRef<any, any>;
  usuarioActual: Usuario = new Usuario();
  videoActual: VideoWall = new VideoWall();
  constructor() {
    this.wallpaperActual = new WallPaper();
    this.videoActual= new VideoWall();
  }
}
