import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  login: boolean = false;
  constructor(private authService: AuthService, private router: Router) {}
  canActivate() {
    if (this.authService.estaLogeado() == false) {
      this.router.navigate["login-provider"];
      return false;
    } else {
      return true;
    }
  }
}
