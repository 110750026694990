import { Component, OnInit } from '@angular/core';
import { WallPaper } from 'src/app/clases/wallpaper.class';
import { DataService } from 'src/app/services/data.service';
import { FondosService } from 'src/app/services/fondos.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';

@Component({
  selector: 'app-public-wallpaper',
  templateUrl: './public-wallpaper.component.html',
  styleUrls: ['./public-wallpaper.component.scss']
})
export class PublicWallpaperComponent implements OnInit {

  fondo: WallPaper;

  constructor(public wallpaperService: FondosService,
    private authService: AuthService,
    public dataService: DataService,
    public dialog: MatDialog) {
    this.fondo = this.dataService.wallpaperActual;
    console.log()
  }

  ngOnInit() {
  }
  darLike(item: WallPaper) {
    if (this.authService.estaLogeado() == true) {
      item.likes = item.likes += 1;
      this.wallpaperService.updateWallpaper(item.documentID, item);
    } else {
      this.dataService.dialogRef = this.dialog.open(LoginDialogComponent);
    }
  }

}
