import { Component, OnInit } from "@angular/core";
import { FondosService } from "../services/fondos.service";
import { AuthService } from "../services/auth.service";
import { Usuario } from "../clases/usuario.class";

@Component({
  selector: "app-provider",
  templateUrl: "./provider.component.html",
  styleUrls: ["./provider.component.scss"],
})
export class ProviderComponent implements OnInit {
  opened = false;
  title = "wallpaperProyect";
  usuarioActual: Usuario = new Usuario();
  source: string = "../../../assets/img/perfilfoto.jpg";
  constructor(private authService: AuthService) {
    this.usuarioActual = this.authService.getusuarioActual();
    if (this.usuarioActual.rutaprofileImg != "") {
      this.source = this.usuarioActual.rutaprofileImg;
    }
  }

  ngOnInit() {}
  cerrarsesion() {
    this.authService.logoutUser();
  }
}
