export class Operadores {
  constructor() {}
  multiploSiete(numero: number): boolean {
    var i: number = 1;
    while (i < 1000) {
      if (numero == 7 * i) {
        return true;
      }
      i = i + 1;
    }
    return false;
  }
}
