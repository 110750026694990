import { Injectable } from "@angular/core";
//importaciones de angular firebase
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";

//importaciones de observables y maps
import { Observable } from "rxjs";
import { map, flatMap, subscribeOn } from "rxjs/operators";

// importacion de clases
import { WallPaper } from "../clases/wallpaper.class";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class FondosService {
  private nameCollection = "/wallpapers";
  ColeccionFondos: AngularFirestoreCollection<WallPaper> = null;
  listDocuments: Observable<any[]>;
  lastDocument: WallPaper;
  lastDocumentProfile: WallPaper;

  listWallpapers: WallPaper[] = [];
  listWallpapersNews: WallPaper[] = [];
  listWallpapersProfile: WallPaper[] = [];

  msgResult: boolean = false;
  msgResult2: boolean = false;
  btnAnterior = true;
  btnNext = false;
  btnNextNews = false;
  btnNextProfiles = false;

  constructor(private db: AngularFirestore, private authService: AuthService) {
    this.ColeccionFondos = db.collection(this.nameCollection);
    this.lastDocument = new WallPaper();
    this.lastDocumentProfile = new WallPaper();
  }

  //Crea un Wallpaper
  addWallpaper(fondo: WallPaper) {
    this.ColeccionFondos.add({ ...fondo });
  }

  //optiene objetos con el id del usuario logeado.
  getWallPapersWithDateAndUser(limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref
        .where("userId", "==", this.authService.getIdUsuario())
        .limit(limite)
        .orderBy("fechaCreacion", "desc")
    );
    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;

          return { documentID, ...data };
        })
      )
    );
    this.listDocuments.subscribe((data) => {
      if (this.listWallpapersProfile.length != 0) {
        this.listWallpapersProfile = [];
      }
      if (data.length == 0) {
        this.msgResult2 = true;
        this.btnNextProfiles = true;
        return;
      }
      data.forEach((element) => {
        this.listWallpapersProfile.push(element);
      });
      this.lastDocumentProfile = data[data.length - 1];
      this.btnNextProfiles = false;
      this.msgResult2 = false;
    });
  }
  getWallPapersWithDateNextAndUser(limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref
        .where("userId", "==", this.authService.getIdUsuario())
        .orderBy("fechaCreacion", "desc")
        .startAfter(this.lastDocumentProfile.fechaCreacion)
        .limit(limite)
    );

    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    this.listDocuments.subscribe((data) => {
      if (data.length == 0) {
        this.msgResult2 = true;
        this.btnNextProfiles = true;
        return;
      }
      data.forEach((element) => {
        this.listWallpapersProfile.push(element);
      });
      this.lastDocumentProfile = data[data.length - 1];
    });
  }

  //optiene objetos con el id del usuario logeado.

  //Optine una lista objetos con fecha.
  getWallPapersWithDate(limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref
        .where("statePublic", "==", "public")
        .where("reported", "==", false)
        .limit(limite)
        .orderBy("fechaCreacion", "desc")
    );
    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;

          return { documentID, ...data };
        })
      )
    );
    this.listDocuments.subscribe((data) => {
      if (this.listWallpapersNews.length != 0) {
        this.listWallpapersNews = [];
      }
      if (data.length == 0) {
        this.msgResult = true;
        this.btnNextNews = true;
        return;
      }
      data.forEach((element) => {
        this.listWallpapersNews.push(element);
      });
      this.lastDocument = data[data.length - 1];
      this.btnNextNews = false;
      this.msgResult = false;
    });
  }
  getWallPapersWithDateNext(limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref
        .where("statePublic", "==", "public")
        .where("reported", "==", false)
        .orderBy("fechaCreacion", "desc")
        .startAfter(this.lastDocument.fechaCreacion)
        .limit(limite)
    );

    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    this.listDocuments.subscribe((data) => {
      if (data.length == 0) {
        this.msgResult = true;
        this.btnNextNews = true;
        return;
      }
      data.forEach((element) => {
        this.listWallpapersNews.push(element);
      });
      this.lastDocument = data[data.length - 1];
    });
  }
  //fin Optine una lista objetos con fecha.

  //Optine una lista objetos con una condicion.
  getWallPapers(limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref
        .where("userId", "==", this.authService.getIdUsuario())
        .limit(limite)
        .orderBy("id")
    );

    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    return this.listDocuments;
  }
  getWallPapersNext(limite: number, ultimoDocumento: WallPaper) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref
        .where("userId", "==", this.authService.getIdUsuario())
        .orderBy("id")
        .startAfter(ultimoDocumento.id)
        .limit(limite)
    );

    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    return this.listDocuments;
  }
  getWallPapersBefore(limite: number, ultimoDocumento: WallPaper) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref
        .where("userId", "==", this.authService.getIdUsuario())
        .orderBy("id")
        .endBefore(ultimoDocumento.id)
        .limit(limite)
    );

    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    return this.listDocuments;
  }
  //obtiene los documentos con un limite
  getWallPapersWithLimit(categoria: string, limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref.orderBy("id").where("categoria", "==", categoria).limit(limite)
    );

    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { ...data };
        })
      )
    );
    return this.listDocuments;
  }
  // optiene los documentos de la seccion de busqueda
  getWallPapersWithSearch(categoria: string, limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref.orderBy("id").where("categoria", "==", categoria).limit(limite)
    );

    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    this.listDocuments.subscribe((res) => {
      if (res.length == 0) {
        this.btnNext = true;
        this.btnAnterior = true;
        this.listWallpapers = [];
        return;
      }
      this.btnNext = false;
      this.listWallpapers = res;
    });
  }
  //optinene los documentos siguientes de un documento es especifico
  getWallPapersWithLimitNetx(
    categoria: string,
    limite: number,
    ultimoDocumento: WallPaper
  ) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref

        .orderBy("id")
        .startAfter(ultimoDocumento.id)
        .where("categoria", "==", categoria)
        .limit(limite)
    );

    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    return this.listDocuments;
  }
  // buecar el siguienet de la seccion de buscar boton de next
  getWallPapersWithSearchNetx(categoria: string, limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref

        .orderBy("id")
        .startAfter(this.listWallpapers[this.listWallpapers.length - 1].id)
        .where("categoria", "==", categoria)
        .limit(limite)
    );

    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    this.listDocuments.subscribe((res) => {
      if (res.length == 0) {
        this.btnAnterior = false;
        this.btnNext = true;
        return;
      }
      this.btnAnterior = false;
      this.listWallpapers = res;
    });
  }
  // optiene los documentos anteriores a un documentos
  getWallPapersWithLimitBefore(
    categoria: string,
    limite: number,
    ultimoDocumento: WallPaper
  ) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref
        .orderBy("id")
        .endBefore(ultimoDocumento.id)
        .where("categoria", "==", categoria)
        .limit(limite)
    );
    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    return this.listDocuments;
  }
  //optine los anteriores de la seccion de buscar boton de anterior
  getWallPapersWithSearchBefore(categoria: string, limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref
        .orderBy("id")
        .endBefore(this.listWallpapers[0].id)
        .where("categoria", "==", categoria)
        .limit(limite)
    );
    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    this.listDocuments.subscribe((res) => {
      if (res.length == 0) {
        this.btnAnterior = true;
        this.btnNext = false;
        return;
      }
      this.listWallpapers = res;
    });
  }
  //obtiene el numero de documentos que tienen una coleccion

  getNumeroDocuemntos() {
    return this.db.collection(this.nameCollection).get();
  }

  //Actualiza un Documento.
  updateWallpaper(key: string, value: WallPaper): Promise<void> {
    return this.ColeccionFondos.doc(key).update(value);
  }
  // Elimina un Documento
  deleteWallpaper(key: string): Promise<void> {
    return this.ColeccionFondos.doc(key).delete();
  }
}
