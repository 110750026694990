import { Component, OnInit, Output, EventEmitter } from "@angular/core";

import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { UsuarioService } from "../services/usuario.service";
import { last } from "rxjs/operators";
import { Usuario } from "../clases/usuario.class";

//importaciones de observables y maps
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

//importar angular storage
import { AngularFireStorage } from "@angular/fire/storage";

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const TELEFONO_REGEXT = /^\d*$/;

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  hide = true;
  form: FormGroup;
  txtFile: boolean = false;
  progressBar: boolean = false;
  messageSucces: boolean = false;
  messageError: boolean = false;

  source: string = "../../assets/svg/famale.svg";
  uploadPorcent: Observable<number>;
  urlImage: Observable<string>;
  showProgressBar: boolean = false;
  fileImg: File;

  @Output() onChange: EventEmitter<File> = new EventEmitter<File>();
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private usuarioService: UsuarioService,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit() {
    this.crearForm();
  }
  crearForm() {
    this.form = this.fb.group({
      nombres: ["", Validators.compose([Validators.required])],
      apellidos: ["", Validators.compose([Validators.required])],
      imagen: ["", Validators.compose([Validators.required])],
      clave: ["", Validators.compose([Validators.required])],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(EMAIL_REGEX),
        ]),
      ],
    });
  }

  CargarArchivo(e) {
    this.fileImg = e.target.files[0];
    console.log(this.fileImg);
    this.projectImage(this.fileImg);
  }
  projectImage(file: File) {
    let reader = new FileReader();
    // TODO: Define type of 'e'
    reader.onload = (e: any) => {
      // Simply set e.target.result as our <img> src in the layout
      this.source = e.target.result;

      this.onChange.emit(file);
    };
    // This will process our file and get it's attributes/data
    reader.readAsDataURL(file);
  }
  registrarse() {
    this.progressBar = true;
    var names = this.form.get("nombres").value;
    var lastNames = this.form.get("apellidos").value;
    var correo = this.form.get("email").value;
    var clave = this.form.get("clave").value;
    this.txtFile= true;

    this.authService
      .createUser(correo, clave)
      .then((res: any) => {
        console.log(res.user.uid);
        localStorage.setItem("id", res.user.uid);
        var usuarioaRegistrar: Usuario = new Usuario();
        usuarioaRegistrar.userId = res.user.uid;
        usuarioaRegistrar.nombres = names;
        usuarioaRegistrar.apellidos = lastNames;
        usuarioaRegistrar.nameInternoImg = "";
        usuarioaRegistrar.rutaprofileImg = "";
        usuarioaRegistrar.calificacion = 0;


        this.showProgressBar = true;
        const id = this.fileImg.name;
        const filepath = "profileImgs/" + this.authService.getIdUsuario();
        usuarioaRegistrar.nameInternoImg = filepath;
        const ref = this.storage.ref(usuarioaRegistrar.nameInternoImg);
        const task = this.storage.upload(
          usuarioaRegistrar.nameInternoImg,
          this.fileImg
        );
        this.uploadPorcent = task.percentageChanges();
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              this.urlImage = ref.getDownloadURL();
              this.urlImage.subscribe((res: string) => {
                usuarioaRegistrar.rutaprofileImg = res;
                // agrega usuario a base de datos

                this.usuarioService
                  .addUsuario(usuarioaRegistrar)
                  .then(() => {
                    this.usuarioService.getUserbyId(usuarioaRegistrar.userId).subscribe((res) => {
                      localStorage.setItem("user", JSON.stringify(res[0]));
                      this.progressBar = false;
                      this.desavilitarCampos();
                      this.messageSucces = true;
                      this.messageError = false;
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                    this.progressBar = false;

                    this.messageError = true;
                  });

                // fin agrega usuario a base de datos

                this.showProgressBar = false;
              });
            })
          )
          .subscribe();
      })
      .catch((err) => {
        console.log(err);

        this.progressBar = false;
        this.messageError = true;
      });
  }

  desavilitarCampos() {
    this.form.controls["nombres"].disable();
    this.form.controls["apellidos"].disable();
    this.form.controls["clave"].disable();
    this.form.controls["email"].disable();
    this.form.controls["imagen"].disable();
  }
}
