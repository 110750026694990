import { Component, OnInit } from "@angular/core";
import { Usuario } from "src/app/clases/usuario.class";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-view-img-profile",
  templateUrl: "./view-img-profile.component.html",
  styleUrls: ["./view-img-profile.component.scss"],
})
export class ViewImgProfileComponent implements OnInit {
  source: string = "../../../assets/img/perfilfoto.jpg";
  usuarioActual: Usuario = new Usuario();
  constructor(private authService: AuthService) {
    this.usuarioActual = this.authService.getusuarioActual();
    if (this.usuarioActual.rutaprofileImg != "") {
      this.source = this.usuarioActual.rutaprofileImg;
    }
  }

  ngOnInit() {}
}
