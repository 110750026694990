import { Component, OnInit } from "@angular/core";
import { WallPaper } from "src/app/clases/wallpaper.class";
import { Usuario } from "src/app/clases/usuario.class";
import { DataService } from "src/app/services/data.service";
import { ViewProfileService } from "src/app/services/view-profile.service";
import { PublicProfileService } from "src/app/services/public-profile.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-public-profile",
  templateUrl: "./public-profile.component.html",
  styleUrls: ["./public-profile.component.scss"],
})
export class PublicProfileComponent implements OnInit {
  source: string = "../../../assets/img/perfilfoto.jpg";
  limite: number = 9;
  usuarioActual: Usuario = new Usuario();
  constructor(
    public wallpaperService: PublicProfileService,
    public dataService: DataService,
    private router: Router
  ) {
    this.usuarioActual = this.dataService.usuarioActual;
    if (
      this.usuarioActual == null ||
      this.usuarioActual == undefined ||
      this.usuarioActual.userId == undefined
    ) {
      this.router.navigate(["trends"]);
    }
    if (this.usuarioActual.rutaprofileImg != "") {
      this.source = this.usuarioActual.rutaprofileImg;
    }
    this.wallpaperService.getWallPapersWithDateAndUser(
      this.limite,
      this.usuarioActual.userId
    );
  }

  ngOnInit() { }
  ShowMoreDocuments() {
    this.wallpaperService.getWallPapersWithDateNextAndUser(
      this.limite,
      this.usuarioActual.userId
    );
  }
  editarWallpaper(item: WallPaper) {
    this.dataService.wallpaperActual = item;
  }
  showWallpaper(fondo: WallPaper) {
    this.dataService.wallpaperActual = fondo;
    this.router.navigate(['/public-wallpaper'])
  }
}
