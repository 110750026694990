import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { UsuarioService } from "../../services/usuario.service";
import { DataService } from "src/app/services/data.service";

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const TELEFONO_REGEXT = /^\d*$/;
@Component({
  selector: "app-login-dialog",
  templateUrl: "./login-dialog.component.html",
  styleUrls: ["./login-dialog.component.scss"],
})
export class LoginDialogComponent implements OnInit {
  hide = true;
  form: FormGroup;
  messageError: boolean = false;
  abrirDialod: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private usuarioService: UsuarioService,
    public dataService: DataService
  ) {}

  ngOnInit() {
    this.crearForm();
  }
  crearForm() {
    this.form = this.fb.group({
      clave: ["", Validators.compose([Validators.required])],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(EMAIL_REGEX),
        ]),
      ],
    });
  }
  login() {
    var correo = this.form.get("email").value;
    var clave = this.form.get("clave").value;
    this.authService
      .loginConCorreAndEmail(correo, clave)
      .then((res: any) => {
        localStorage.setItem("id", res.user.uid);
        this.usuarioService.getUserbyId(res.user.uid).subscribe((res) => {
          localStorage.setItem("user", JSON.stringify(res[0]));
        });
        this.dataService.dialogRef.close();
      })
      .catch((err) => {
        this.messageError = true;
        console.log("usuario y contraseña incorrecta");
        return;
      });
  }
  goToRegister() {
    this.dataService.dialogRef.close();
    this.router.navigate(["/registrarse"]);
  }
}
