import { Component, OnInit } from "@angular/core";
import { WallPaper } from "src/app/clases/wallpaper.class";
import { DataService } from "src/app/services/data.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-view-wallpaper",
  templateUrl: "./view-wallpaper.component.html",
  styleUrls: ["./view-wallpaper.component.scss"],
})
export class ViewWallpaperComponent implements OnInit {
  fondoActual: WallPaper = new WallPaper();
  source: string = "../../../assets/img/anf-05.jpg";

  constructor(public dataService: DataService, private router: Router) {}

  ngOnInit() {
    this.fondoActual = this.dataService.wallpaperActual;
    if (this.fondoActual.nombre == undefined) {
      this.router.navigate(["/home"]);
    }
    this.source = this.dataService.wallpaperActual.ruta;
  }
}
