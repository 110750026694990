import { Component, OnInit } from "@angular/core";
import { FondosService } from "src/app/services/fondos.service";
import { WallPaper } from "src/app/clases/wallpaper.class";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { MatDialog } from "@angular/material/dialog";
import { LoginDialogComponent } from "../login-dialog/login-dialog.component";

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.scss"],
})
export class NewsComponent implements OnInit {
  fondos: WallPaper[] = [];

  constructor(
    public wallpaperService: FondosService,
    private authService: AuthService,
    public dataService: DataService,
    public dialog: MatDialog
  ) {
    if (this.wallpaperService.listWallpapersNews.length == 0) {
      this.wallpaperService.getWallPapersWithDate(5);
    }
  }

  ngOnInit() {}
  moreWallpaper() {
    this.wallpaperService.getWallPapersWithDateNext(5);
  }
  darLike(item: WallPaper) {
    if (this.authService.estaLogeado() == true) {
      item.likes = item.likes += 1;
      this.wallpaperService.updateWallpaper(item.documentID, item);
    } else {
      this.dataService.dialogRef = this.dialog.open(LoginDialogComponent);
    }
  }
}
