import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Usuario } from "src/app/clases/usuario.class";

//importaciones de observables y maps
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
//importar angular storage
import { AngularFireStorage } from "@angular/fire/storage";
import { UsuarioService } from "src/app/services/usuario.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-profile-provider",
  templateUrl: "./profile-provider.component.html",
  styleUrls: ["./profile-provider.component.scss"],
})
export class ProfileProviderComponent implements OnInit {
  form: FormGroup;
  usuarioActual: Usuario = new Usuario();
  fileImg: File;
  source: string = "../../../assets/img/perfilfoto.jpg";
  uploadPorcent: Observable<number>;
  urlImage: Observable<string>;
  showProgressBar: boolean = false;
  btnEditar = false;

  @Output() onChange: EventEmitter<File> = new EventEmitter<File>();
  constructor(
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private usuarioService: UsuarioService,
    private authService: AuthService
  ) {
    this.usuarioActual = this.authService.getusuarioActual();
    if (this.usuarioActual.rutaprofileImg != "") {
      this.source = this.usuarioActual.rutaprofileImg;
    }
  }

  ngOnInit() {
    this.crearForm();
    this.desavilitarCampos();
  }
  crearForm() {
    this.form = this.fb.group({
      nombres: [
        this.usuarioActual.nombres,
        Validators.compose([Validators.required]),
      ],
      apellidos: [
        this.usuarioActual.apellidos,
        Validators.compose([Validators.required]),
      ],
      imagen: [""],
    });
  }
  CargarArchivo(e) {
    this.fileImg = e.target.files[0];
    console.log(this.fileImg);
    this.projectImage(this.fileImg);
  }
  projectImage(file: File) {
    let reader = new FileReader();
    // TODO: Define type of 'e'
    reader.onload = (e: any) => {
      // Simply set e.target.result as our <img> src in the layout
      this.source = e.target.result;

      this.onChange.emit(file);
    };
    // This will process our file and get it's attributes/data
    reader.readAsDataURL(file);
  }
  actualizarUsuario() {
    if (this.fileImg == null) {
      this.desavilitarCampos();
      this.usuarioActual.nombres = this.form.get("nombres").value;
      this.usuarioActual.apellidos = this.form.get("apellidos").value;
      this.usuarioService.updateUsuario(
        this.usuarioActual.documentID,
        this.usuarioActual
      );
      localStorage.setItem("user", JSON.stringify(this.usuarioActual));
    } else {
      this.desavilitarCampos();
      this.showProgressBar = true;
      const id = this.fileImg.name;
      const filepath = "profileImgs/" + this.authService.getIdUsuario();
      this.usuarioActual.nameInternoImg = filepath;
      const ref = this.storage.ref(this.usuarioActual.nameInternoImg);
      const task = this.storage.upload(
        this.usuarioActual.nameInternoImg,
        this.fileImg
      );
      this.uploadPorcent = task.percentageChanges();
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.urlImage = ref.getDownloadURL();
            this.urlImage.subscribe((res: string) => {
              this.usuarioActual.rutaprofileImg = res;
              this.usuarioActual.nombres = this.form.get("nombres").value;
              this.usuarioActual.apellidos = this.form.get("apellidos").value;

              this.usuarioService.updateUsuario(
                this.usuarioActual.documentID,
                this.usuarioActual
              );
              this.showProgressBar = false;
              localStorage.setItem("user", JSON.stringify(this.usuarioActual));
            });
          })
        )
        .subscribe();
    }
    this.btnEditar = false;
  }
  desavilitarCampos() {
    this.form.controls["nombres"].disable();
    this.form.controls["apellidos"].disable();
    this.form.controls["imagen"].disable();
  }
  editar() {
    this.form.controls["nombres"].enable();
    this.form.controls["apellidos"].enable();
    this.form.controls["imagen"].enable();
    this.btnEditar = true;
  }
}
