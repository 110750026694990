import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
//Angular Material Modulo
import { MaterialModule } from "./material.module";

//firebase
import { AngularFireModule } from "@angular/fire";
import {
  AngularFirestoreModule,
  FirestoreSettingsToken,
} from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";

// routing
import { APP_ROUTES } from "./app.routes";
//formularios
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

//envairoment
import { environment } from "../environments/environment";
import { HomeComponent } from "./components/home/home.component";
import { ProviderComponent } from "./provider/provider.component";
import { HomeProviderComponent } from "./provider/home-provider/home-provider.component";
import { TableWallpaperComponent } from "./provider/table-wallpaper/table-wallpaper.component";
import { EditDialogComponent } from "./provider/table-wallpaper/edit-dialog/edit-dialog.component";
import { ViewDialogComponent } from "./provider/table-wallpaper/view-dialog/view-dialog.component";
import { CustomerComponent } from "./customer/customer.component";
import { HomeCustomerComponent } from "./customer/home-customer/home-customer.component";
import { ViewWallpaperComponent } from "./customer/view-wallpaper/view-wallpaper.component";
import { ViewGalleryComponent } from "./customer/view-gallery/view-gallery.component";
import { SearchComponent } from "./customer/search/search.component";
import { ProfileProviderComponent } from "./provider/profile-provider/profile-provider.component";
import { ViewimgProfileComponent } from "./provider/viewimg-profile/viewimg-profile.component";
import { LoginDialogComponent } from "./customer/login-dialog/login-dialog.component";
import { RegisterComponent } from './register/register.component';
import { NewsComponent } from './customer/news/news.component';
import { AddWallpaperComponent } from './customer/add-wallpaper/add-wallpaper.component';
import { ViewProfileComponent } from './customer/view-profile/view-profile.component';
import { EditProfileComponent } from './customer/edit-profile/edit-profile.component';
import { ViewImgProfileComponent } from './customer/view-img-profile/view-img-profile.component';
import { EditWallpaperComponent } from './customer/edit-wallpaper/edit-wallpaper.component';
import { TrendsComponent } from './customer/trends/trends.component';
import { PublicProfileComponent } from './customer/public-profile/public-profile.component';
import { PublicWallpaperComponent } from './customer/public-wallpaper/public-wallpaper.component';
import { ViewImgProfilePublicComponent } from './customer/view-img-profile-public/view-img-profile-public.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AddVideoComponent } from './customer/add-video/add-video.component';
import { ViewVideosComponent } from './customer/view-videos/view-videos.component';
import { ViewVideoDetailComponent } from './customer/view-video-detail/view-video-detail.component';
import { AddVideoExternoComponent } from './customer/add-video-externo/add-video-externo.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProviderComponent,
    HomeProviderComponent,
    TableWallpaperComponent,
    EditDialogComponent,
    ViewDialogComponent,
    CustomerComponent,
    HomeCustomerComponent,
    ViewWallpaperComponent,
    ViewGalleryComponent,
    SearchComponent,
    ProfileProviderComponent,
    ViewimgProfileComponent,
    LoginDialogComponent,
    RegisterComponent,
    NewsComponent,
    AddWallpaperComponent,
    ViewProfileComponent,
    EditProfileComponent,
    ViewImgProfileComponent,
    EditWallpaperComponent,
    TrendsComponent,
    PublicProfileComponent,
    PublicWallpaperComponent,
    ViewImgProfilePublicComponent,
    AddVideoComponent,
    ViewVideosComponent,
    ViewVideoDetailComponent,
    AddVideoExternoComponent,
    LoginComponent,
  ],
  imports: [
    MaterialModule,
    BrowserModule,
    APP_ROUTES,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  entryComponents: [
    EditDialogComponent,
    ViewDialogComponent,
    LoginDialogComponent,
  ],

  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
