import { Component, OnInit } from '@angular/core';
import { Usuario } from "src/app/clases/usuario.class";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-view-img-profile-public',
  templateUrl: './view-img-profile-public.component.html',
  styleUrls: ['./view-img-profile-public.component.scss']
})
export class ViewImgProfilePublicComponent implements OnInit {
  source: string = "../../../assets/img/perfilfoto.jpg";
  usuarioActual: Usuario = new Usuario();
  constructor(public dataService: DataService) { 
    this.usuarioActual = this.dataService.usuarioActual;
    if (this.usuarioActual.rutaprofileImg != "") {
      this.source = this.usuarioActual.rutaprofileImg;
    }
  }

  ngOnInit() {
  }

}
