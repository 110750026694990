
import { Component, OnInit } from '@angular/core';
import { VideoWall } from 'src/app/clases/video.class';
import { DataService } from 'src/app/services/data.service';
import { VideoService } from 'src/app/services/video.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-view-videos',
  templateUrl: './view-videos.component.html',
  styleUrls: ['./view-videos.component.scss']
})
export class ViewVideosComponent implements OnInit {

  constructor(public videoService: VideoService,public dataService: DataService, private router: Router) { 
    this.videoService.getVideosWithDateAndUser(50);
    console.log(this.videoService.listVideosProfile)
  }

  ngOnInit() {
  }
  verDetallesVideo(item: VideoWall) {
    this.dataService.videoActual = item;
    this.router.navigate(["detail-video"]);
  }

}
