import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "view-dialog.component",
  templateUrl: "./view-dialog.component.html",
  styleUrls: ["./view-dialog.component.scss"],
})
export class ViewDialogComponent implements OnInit {
  source: string = "../../../assets/img/img-icon.png";
  constructor(public dataService: DataService) {}
  ngOnInit() {
    this.source = this.dataService.wallpaperActual.ruta;
  }
}
