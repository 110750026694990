import { Injectable } from "@angular/core";
import { Usuario } from "../clases/usuario.class";
//importamos mosulos de angular firestore
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";

//importaciones de observables y maps
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UsuarioService {
  private nameCollection = "/usuarios";
  ColeccionUsuarios: AngularFirestoreCollection<Usuario> = null;
  listDocuments: Observable<any[]>;
  listUsuarios: Usuario[] = [];

  constructor(private db: AngularFirestore) {
    this.ColeccionUsuarios = db.collection(this.nameCollection);
  }

  //optien un usuario por id
  getUserbyId(id: string) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref.where("userId", "==", id)
    );

    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    return this.listDocuments;
  }

  //get usuario por caificacion
  getusersWithQualification(limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref.limit(limite).orderBy("calificacion", "desc")
    );

    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    this.listDocuments.subscribe((data) => {
      if (this.listUsuarios.length != 0) {
        this.listUsuarios = [];
      }

      data.forEach((element) => {
        this.listUsuarios.push(element);
      });
    });
  }

  //get usuario por caificacion

  //guardar usuario Actual

  //crea un wallpaper
  addUsuario(user: Usuario) {
    return this.ColeccionUsuarios.add({ ...user });
  }

  updateUsuario(key: string, value: Usuario): Promise<void> {
    return this.ColeccionUsuarios.doc(key).update(value);
  }
  // Elimina un Documento
  deleteUsuario(key: string): Promise<void> {
    return this.ColeccionUsuarios.doc(key).delete();
  }
}
