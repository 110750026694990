import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
//imporst maps y observable
import { Observable } from "rxjs";
import { map, flatMap, subscribeOn } from "rxjs/operators";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { WallPaper } from "../clases/wallpaper.class";
@Injectable({
  providedIn: "root",
})
export class TrendsService {
  private nameCollection = "/wallpapers";
  listDocuments: Observable<any[]>;
  ColeccionFondos: AngularFirestoreCollection<WallPaper> = null;
  lastDocumentProfile: WallPaper;
  listWallpapersProfile: WallPaper[] = [];

  msgResult2: boolean = false;
  btnNextProfiles = false;
  constructor(private db: AngularFirestore, private authService: AuthService) {
    this.ColeccionFondos = db.collection(this.nameCollection);

    this.lastDocumentProfile = new WallPaper();
  }
  //optiene tendencias

  getWallPapersWithTrends(limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref
        .where("statePublic", "==", "public")
        .where("reported", "==", false)
        .limit(limite)
        .orderBy("likes", "desc")
    );
    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;

          return { documentID, ...data };
        })
      )
    );
    this.listDocuments.subscribe((data) => {
      if (this.listWallpapersProfile.length != 0) {
        this.listWallpapersProfile = [];
      }
      if (data.length == 0) {
        this.msgResult2 = true;
        this.btnNextProfiles = true;
        return;
      }
      data.forEach((element) => {
        this.listWallpapersProfile.push(element);
      });
      this.lastDocumentProfile = data[data.length - 1];
      this.btnNextProfiles = false;
      this.msgResult2 = false;
    });
  }
  getWallPapersWithTrendsNext(limite: number) {
    const query = this.db.collection(this.nameCollection, (ref) =>
      ref
        .where("statePublic", "==", "public")
        .where("reported", "==", false)
        .orderBy("likes", "desc")
        .startAfter(this.lastDocumentProfile.likes)
        .limit(limite)
    );

    this.listDocuments = query.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const documentID = a.payload.doc.id;
          return { documentID, ...data };
        })
      )
    );
    this.listDocuments.subscribe((data) => {
      if (data.length == 0) {
        this.msgResult2 = true;
        this.btnNextProfiles = true;
        return;
      }
      data.forEach((element) => {
        this.listWallpapersProfile.push(element);
      });
      this.lastDocumentProfile = data[data.length - 1];
    });
  }

  //optiene objetos con el id del usuario logeado.
}
