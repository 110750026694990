export class WallPaper {
  nombre: string;
  nameInterno: string;
  ruta: string;
  categoria: string;
  userId: string;
  documentID: string;
  id: number;
  fechaCreacion: Date;
  likes: number;
  reported: boolean;
  statePublic: string;
}



