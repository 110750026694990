import { Component, OnInit, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { EditDialogComponent } from "./edit-dialog/edit-dialog.component";
import { FondosService } from "src/app/services/fondos.service";
import { WallPaper } from "src/app/clases/wallpaper.class";
import { DataService } from "src/app/services/data.service";
import { ViewDialogComponent } from "./view-dialog/view-dialog.component";

@Component({
  selector: "app-table-wallpaper",
  templateUrl: "./table-wallpaper.component.html",
  styleUrls: ["./table-wallpaper.component.scss"],
})
export class TableWallpaperComponent implements OnInit {
  btnAnterior = true;
  btnNext = false;
  limite: number = 10;
  id: string = "12345";
  fondos: WallPaper[] = [];
  constructor(
    public dialog: MatDialog,
    public wallpaperService: FondosService,
    public dataService: DataService
  ) {
    this.wallpaperService.getWallPapers(this.limite).subscribe((data) => {
      this.fondos = data;
    });
  }

  ngOnInit() {}
  openDialog(item: WallPaper) {
    this.dataService.wallpaperActual = item;
    const dialogRef = this.dialog.open(EditDialogComponent);
  }
  showImage(item: WallPaper) {
    this.dataService.wallpaperActual = item;
    const dialogRef = this.dialog.open(ViewDialogComponent);
  }
  nextElemets() {
    this.wallpaperService
      .getWallPapersNext(this.limite, this.fondos[this.fondos.length - 1])
      .subscribe((data) => {
        if (data.length == 0) {
          this.btnNext = true;
          return;
        }
        this.fondos = data;
        this.btnAnterior = false;
      });
  }
  beforeElements() {
    this.wallpaperService
      .getWallPapersBefore(this.limite, this.fondos[0])
      .subscribe((data) => {
        console.log(data);
        if (data.length == 0) {
          this.btnAnterior = true;
          this.btnNext = false;
          return;
        }
        this.btnNext = false;
        this.fondos = data;
      });
  }
}
