import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  constructor(private authService: AuthService) {
    this.estalogeado();
  }

  ngOnInit() {}

  estalogeado() {
    this.authService.isLogin();
  }
  logout() {
    this.authService.logoutUser();
  }
  crearUsuario() {
    this.authService
      .createUser("eduarsepulveda2@gmail.com", "3115487301")
      .then((res) => {
        console.log("registro correctamente");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
