import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

//importaciones de observables y maps
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
//importar angular storage
import { AngularFireStorage } from "@angular/fire/storage";
import { WallPaper } from "src/app/clases/wallpaper.class";
import { FondosService } from "src/app/services/fondos.service";
import { AuthService } from "src/app/services/auth.service";
import { Hora } from "src/app/clases/Hora";
@Component({
  selector: "app-add-wallpaper",
  templateUrl: "./add-wallpaper.component.html",
  styleUrls: ["./add-wallpaper.component.scss"],
})
export class AddWallpaperComponent implements OnInit {
  txtFile: boolean = false;
  fechas: Hora = new Hora();
  form: FormGroup;
  fondoActual: WallPaper = new WallPaper();
  fileImg: File;
  source: string = "../../../assets/svg/img-1.svg";
  uploadPorcent: Observable<number>;
  urlImage: Observable<string>;
  message: boolean = false;
  showProgressBar: boolean = false;
  btnOtherImage: boolean = true;
  sizeCollecion: number;

  @Output() onChange: EventEmitter<File> = new EventEmitter<File>();
  constructor(
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private WallpaperService: FondosService,
    private authService: AuthService
  ) {
    this.WallpaperService.getNumeroDocuemntos().subscribe((res) => {
      this.sizeCollecion = res.size;
    });
  }

  ngOnInit() {
    this.crearForm();
  }
  crearForm() {
    this.form = this.fb.group({
      nameImage: ["", Validators.compose([Validators.required])],
      categoria: ["", Validators.compose([Validators.required])],
      estadoPublico: ["", Validators.compose([Validators.required])],
      imagen: ["", Validators.compose([Validators.required])],
    });
  }
  CargarArchivo(e) {
    this.fileImg = e.target.files[0];
    console.log(this.fileImg);
    this.projectImage(this.fileImg);
  }
  projectImage(file: File) {
    let reader = new FileReader();
    // TODO: Define type of 'e'
    reader.onload = (e: any) => {
      // Simply set e.target.result as our <img> src in the layout
      this.source = e.target.result;

      this.onChange.emit(file);
    };
    // This will process our file and get it's attributes/data
    reader.readAsDataURL(file);
  }
  agregarImagenes() {
    var nombreImagen = this.form.get("nameImage").value;
    var categoria = this.form.get("categoria").value;
    console.log(nombreImagen + "  " + categoria);
  }
  subirImagenes() {
    this.desavilitarCampos();
    this.showProgressBar = true;
    const id = this.fechas.getFechaHoy() + this.authService.getIdUsuario();
    const filepath = "wallpapers/" + id;
    this.fondoActual.nameInterno = filepath;
    const ref = this.storage.ref(filepath);
    const task = this.storage.upload(filepath, this.fileImg);
    this.uploadPorcent = task.percentageChanges();
    task
      .snapshotChanges()
      .pipe(
        finalize(async () => {
          this.urlImage = ref.getDownloadURL();
          this.urlImage.subscribe((res: string) => {
            this.fondoActual.ruta = res;
            this.fondoActual.nombre = this.form.get("nameImage").value;
            this.fondoActual.categoria = this.form.get("categoria").value;
            this.fondoActual.statePublic = this.form.get("estadoPublico").value;
            this.fondoActual.userId = this.authService.getIdUsuario();
            this.fondoActual.fechaCreacion = new Date();
            this.fondoActual.likes = 0;
            this.fondoActual.reported = false;
            this.fondoActual.id = this.sizeCollecion += 1;
            this.WallpaperService.addWallpaper(this.fondoActual);
            this.showProgressBar = false;
            this.btnOtherImage = false;
          });
        })
      )
      .subscribe();
  }
  desavilitarCampos() {
    this.form.controls["nameImage"].disable();
    this.form.controls["categoria"].disable();
    this.form.controls["estadoPublico"].disable();
    this.form.controls["imagen"].disable();
    this.txtFile = true;
  }
  nuevaImagen() {
    this.fileImg = null;
    this.uploadPorcent = new Observable();
    this.form.controls["nameImage"].setValue("");
    this.form.controls["categoria"].setValue("");
    this.form.controls["estadoPublico"].setValue("");
    this.source = "../../../assets/svg/img-1.svg";
    this.form.controls["nameImage"].enable();
    this.form.controls["categoria"].enable();
    this.form.controls["estadoPublico"].enable();
    this.form.controls["imagen"].enable();
    this.btnOtherImage = true;
    this.txtFile = false;
  }
}
