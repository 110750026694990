import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { WallPaper } from "src/app/clases/wallpaper.class";
import { DataService } from "src/app/services/data.service";

//importaciones de observables y maps
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
//importar angular storage
import { AngularFireStorage } from "@angular/fire/storage";
import { FondosService } from "src/app/services/fondos.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-edit-wallpaper",
  templateUrl: "./edit-wallpaper.component.html",
  styleUrls: ["./edit-wallpaper.component.scss"],
})
export class EditWallpaperComponent implements OnInit {
  form: FormGroup;
  fondoActual: WallPaper = new WallPaper();
  fileImg: File;
  source: string = "../../../assets/img/img-icon.png";
  uploadPorcent: Observable<number>;
  urlImage: Observable<string>;
  message: boolean = false;
  btnEditar: boolean = false;
  showProgressBar: boolean = false;

  @Output() onChange: EventEmitter<File> = new EventEmitter<File>();
  constructor(
    private fb: FormBuilder,
    public dataService: DataService,
    private storage: AngularFireStorage,
    private WallpaperService: FondosService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.fondoActual = this.dataService.wallpaperActual;
    this.source = this.dataService.wallpaperActual.ruta;
    this.crearForm();
    this.desavilitarCampos();
  }
  crearForm() {
    this.form = this.fb.group({
      nameImage: [
        this.fondoActual.nombre,
        Validators.compose([Validators.required]),
      ],
      categoria: [
        this.fondoActual.categoria,
        Validators.compose([Validators.required]),
      ],
      estadoPublico: [
        this.fondoActual.statePublic,
        Validators.compose([Validators.required]),
      ],
      imagen: [""],
    });
  }
  CargarArchivo(e) {
    this.fileImg = e.target.files[0];
    this.projectImage(this.fileImg);
  }
  projectImage(file: File) {
    let reader = new FileReader();
    // TODO: Define type of 'e'
    reader.onload = (e: any) => {
      // Simply set e.target.result as our <img> src in the layout
      this.source = e.target.result;

      this.onChange.emit(file);
    };
    // This will process our file and get it's attributes/data
    reader.readAsDataURL(file);
  }

  actualizarWallpaper() {
    if (this.fileImg == null) {
      this.desavilitarCampos();
      this.fondoActual.nombre = this.form.get("nameImage").value;
      this.fondoActual.categoria = this.form.get("categoria").value;
      this.fondoActual.statePublic = this.form.get("estadoPublico").value;
      this.fondoActual.userId = this.authService.getIdUsuario();
      this.WallpaperService.updateWallpaper(
        this.fondoActual.documentID,
        this.fondoActual
      );

      this.btnEditar = false;
    } else {
      this.showProgressBar = true;
      this.desavilitarCampos();
      const filepath = this.fondoActual.nameInterno;
      const ref = this.storage.ref(filepath);
      const task = this.storage.upload(filepath, this.fileImg);
      this.uploadPorcent = task.percentageChanges();
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.urlImage = ref.getDownloadURL();
            this.urlImage.subscribe((res: string) => {
              this.fondoActual.ruta = res;
              this.source = res;
              this.fondoActual.nombre = this.form.get("nameImage").value;
              this.fondoActual.categoria = this.form.get("categoria").value;
              this.fondoActual.statePublic = this.form.get(
                "estadoPublico"
              ).value;
              this.fondoActual.fechaCreacion = new Date();
              this.WallpaperService.updateWallpaper(
                this.fondoActual.documentID,
                this.fondoActual
              );
              this.btnEditar = false;
              this.showProgressBar = false;
              this.fileImg = null;
            });
          })
        )
        .subscribe();
    }
  }
  desavilitarCampos() {
    this.form.controls["nameImage"].disable();
    this.form.controls["categoria"].disable();
    this.form.controls["estadoPublico"].disable();
    this.form.controls["imagen"].disable();
  }
  editar() {
    this.form.controls["nameImage"].enable();
    this.form.controls["categoria"].enable();
    this.form.controls["estadoPublico"].enable();
    this.form.controls["imagen"].enable();
    this.btnEditar = true;
    this.uploadPorcent = new Observable();
  }
}
